import styled from 'styled-components';

export const HtmlSiteMapHeader = styled.a`
@media (min-width: 991px) {
  padding-left: ${(props) => props.$leftSpace * 30}px;
}
  
`;

export const HtmlSitemapTitle = styled.h4`
font-size: 20px;
font-weight: normal;
`;

export const HtmlSitemapMainDiv = styled.div `
margin: 30px;
`;